@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.hero {
  display: flex;
  justify-content: center;
  img {
    border: none;
  }
  &__bigBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 414px;
    margin-top: 108px;
  }
  &__button {
    border: none;
    background-color: #000000;
    color: #ffffff;
    width: 164px;
    height: 54px;
    margin-top: 92px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }
  &__divider {
    width: 363px;
    height: 1px;
    background: #949494;
    color: #949494;
    margin-right: 65px;
    margin-top: 128px;
  }
  &__desktop {
    display: none;
  }
  @media screen and (min-width: 1125px) {
    &__mobile {
      display: none;
    }
    &__desktop {
      display: initial;
    }
    &__divider {
      display: none;
    }
    &__bigBox {
      margin-top: 125px;
    }
  }
}
