@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__bigBox {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 52px;
    letter-spacing: 0.07em;
    text-align: left;
    margin-top: 84px;
    width: 317px;
  }
  &__text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    width: 312.62px;
  }
  &__button {
    border: none;
    background-color: black;
    color: white;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    width: 156px;
    height: 46px;
    align-self: flex-start;
    margin-top: 32px;
    cursor: pointer;
  }
  &__divider {
    width: 363px;
    height: 1px;
    background: #949494;
    color: #949494;
    margin-left: 65px;
    margin-top: 76px;
  }
  @media screen and (min-width: 1125px) {
    &__title {
      width: 722.51px;
      margin-left: 93px;
      margin-top: 225px;
    }
    &__bigBox {
      width: 1125px;
    }
    &__text {
      margin-left: 93px;
      width: 950px;
    }
    &__button {
      margin-left: 93px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__divider {
      width: 898px;
    }
  }
}
