.form {
  display: flex;
  flex-direction: column;
  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 52px;
    width: 414px;
    margin-inline: auto;
    & h2 {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 3.5625rem;
    }
  }
  &__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    label {
      margin: 12px 5px 5px;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
    input {
      background-color: #ffffff;
      width: 354.4px;
      border: 1px solid #656565;
      border-radius: 5px;
      padding-left: 16px;
      padding-block: 13px;
      font-size: 14px;
      line-height: 21px;
      font-weight: 300;
      &:focus-visible {
        outline: #363636;
        border-color: #363636;
      }
    }
  }
  textarea {
    min-height: 90px;
    background-color: #ffffff;
    border: 1px solid #656565;
    border-radius: 5px;
    padding-left: 20px;
    padding-top: 12px;
    width: 354.4px;
    &:focus-visible {
      outline: #363636;
      border-color: #363636;
    }
  }
  &__button {
    background-color: black;
    border: none;
    color: #ffffff;
    width: 354.4px;
    height: 46px;
    margin-top: 20px;
    margin-bottom: 55px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    cursor: pointer;
  }
  @media screen and (min-width: 1125px) {
    position: absolute;
    right: 38px;
    &__box {
      background-color: #ffffff;
      width: 500px;
      height: 550px;
    }
    &__label {
      input {
        width: 428px;
      }
    }
    textarea {
      width: 428px;
    }
    &__button {
      width: 428px;
      height: 48px;
      margin-bottom: 0px;
    }
  }
}

::placeholder {
  font-family: "Poppins";
}
