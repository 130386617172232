@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.contact {
  display: flex;
  justify-content: center;
  &__bigBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 414px;
    margin-top: 108px;
  }
  &__background {
    background-image: url(../../assets/background.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 414px;
    height: 491.47px;
  }
  &__h1 {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 850;
    font-size: 54px;
    line-height: 70px;
    color: #ffffff;
    margin-top: 82px;
    letter-spacing: 0.07em;
  }
  &__info {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
  }
  &__box {
    width: 300px;
    text-align: start;
    margin-left: 49px;
  }
  a {
    text-decoration: none;
  }
  &__emailIcon {
    margin-right: 12px;
  }
  &__textIcon {
    margin-right: 12px;
  }
  &__container {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  @media screen and (min-width: 1125px) {
    &__background {
      background-image: url(../../assets/BET_contact_bg.svg);
      width: 844px;
      height: 590px;
      margin-left: 53px;
    }
    &__bigBox {
      width: 1125px;

      margin-bottom: 73px;
      display: flex;
      flex-direction: row;
      position: relative;
    }
    &__h1 {
      width: 411px;
      margin-bottom: 73px;
    }
    &__box {
      margin-left: 65px;
    }
  }
}
