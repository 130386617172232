@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.footer {
  background-color: #000000;
  display: flex;
  justify-content: center;
  &__background {
    background-image: url(../../assets/bet_mobile_footer.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 414px;
    height: 380px;
  }
  &__info {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
  }
  &__phone {
    margin-top: 204px;
    margin-bottom: 20px;
  }
  &__logo {
    display: none;
  }

  @media screen and (min-width: 1125px) {
    &__background {
      background-image: none;
      display: flex;
      width: 1125px;
      height: 225px;
    }
    &__logo {
      display: initial;
      width: 217.73px;
      height: 63.41px;
      margin-top: 66.8px;
    }
    &__phone {
      margin-top: 85px;
      margin-left: 464.54px;
      margin-right: 53px;
      width: 131px;
    }
    &__email {
      margin-top: 85px;
    }
  }
}
