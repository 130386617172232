@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.services {
  display: flex;
  justify-content: center;
  &__truck {
    display: none;
  }
  &__list {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
  }
  &__h1 {
    margin-top: 130px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 52px;
  }
  &__bullet {
    width: 10px;
    height: 10px;
    margin-right: 48px;

    &--1 {
      width: 10px;
      height: 10px;
      margin-right: 48px;
    }
    &--2 {
      margin-top: 67px;
      width: 10px;
      height: 10px;
      margin-right: 48px;
    }
    &--3 {
      margin-top: 80px;
      width: 10px;
      height: 10px;
      margin-right: 48px;
    }
    &--4 {
      margin-top: 124px;
      width: 10px;
      height: 10px;
      margin-right: 48px;
    }
  }
  &__box {
    display: flex;
    margin-top: 25px;
  }
  &__text {
    &--1 {
      width: 234px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      letter-spacing: 0.07em;
      text-align: start;
    }
    &--2 {
      width: 234px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      letter-spacing: 0.07em;
      text-align: start;
      margin-top: 51px;
    }
    &--3 {
      width: 234px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      letter-spacing: 0.07em;
      text-align: start;
      margin-top: 51.44px;
    }
    &--4 {
      width: 234px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      letter-spacing: 0.07em;
      text-align: start;
      margin-top: 53px;
      margin-bottom: 0px;
    }
  }
  @media screen and (min-width: 1125px) {
    &__truck {
      display: initial;
    }
    &__box {
      display: none;
    }
  }
}
