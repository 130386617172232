@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__bigBox {
    display: flex;
    width: 318px;
    height: 100%;
    flex-direction: column;
  }
  &__text {
    display: flex;
    flex-direction: column;
  }
  &__h1 {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 70px;
    width: fit-content;
  }
  &__h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 44px;
    text-align: start;
    width: 287px;
  }
  &__h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: start;
    width: 315px;
  }
  @media screen and (min-width: 1125px) {
    &__bigBox {
      width: 1125px;
      display: flex;
      flex-direction: row;
    }
    &__h1 {
      margin-left: 93px;
      margin-top: 94px;
      margin-right: 94px;
    }
    &__h2 {
      width: 575px;
      margin-top: 110px;
    }
    &__h3 {
      width: 614px;
    }
    &__text {
      display: flex;
    }
  }
}
